/** @jsx jsx */
import { jsx } from "theme-ui"
// // import PropTypes from 'prop-types'

import React, { useState } from 'react'
import { Link } from 'gatsby'
import { IconContext } from "react-icons"
import {RiMenuFill, RiMenu2Line } from "react-icons/ri"
import ThemeSwitcher from "../ThemeSwitcher"

const Burger = ({menuLinks}) => {
    const [open, setOpen] = useState(false)
    return (
      <>
        <div onClick={e => {setOpen(open === false ? true : false)} } sx={{
                        display: [null,null,"none","none","none"],
                        listStyleType: `none`,
                        // lineHeight:`36px`,
                        padding: `0.5rem`,
                      }}>
                <IconContext.Provider value={{ color: "text", size:"24", className: "icon-f" }}>
                  {open === false ? <RiMenuFill /> : <RiMenu2Line />}
                </IconContext.Provider>
        </div> 
        {open && (<div sx={{
          display: [null,null,"none","none","none"], 
          position:'fixed', 
          right:0, 
          backgroundColor:"var(--theme-ui-colors-backgroundLighten20)",
          zIndex: 10,
          width:'50%',
          height:'calc(100vh - 24px)',
          }} >
          <ul style={{margin:0, padding:0,}}>
            {menuLinks.map(link => (
                        <li
                          key={link.name}
                          sx={{
                            listStyleType: `none`,
                            lineHeight:`24px`,
                            padding: `.6rem 2rem`,
                            "a:hover" :{ textDecoration: `none`},
                            "&:hover": {
                              borderLeft: "3px solid rgba(102,51,153,.7)",
                              background: "var(--theme-ui-colors-backgroundLighten10)"
                            } 
                          }}
                        >
                          <Link to={link.link}>
                            {link.name}
                          </Link>
                        </li>
                      ))}
            <li style={{
                        listStyleType: `none`,
                        padding: `1rem`,
                      }}><ThemeSwitcher /> </li>
          </ul>        
        </div>)}
      </>
    )
  }
  export default Burger
