/** @jsx jsx */
import { jsx } from "theme-ui"
import React from 'react'
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Burger from "./Burger"
import ThemeSwitcher from "../ThemeSwitcher"


const Header = ({ siteTitle, menuLinks }) => {

  return(
    <>
      <header
        sx={{
          background: "var(--theme-ui-colors-backgroundLighten10)",
          color: "text",
          marginBottom: ["0px", "0px", "1.45rem", "1.45rem", "1.45rem"],
          position: ["fixed","fixed","relative","relative","relative"],
          top: 0,
          zIndex:10,
          width:'100%',
          borderBottom: 'solid #898 1px'
        }}
      >
        <div
          style={{
            background: "var(--theme-ui-colors-backgroundLighten10)",
          }}
        >
          <div
            style={{
              margin: "0 auto",
              maxWidth: 960,
              padding: "0 1.0875rem",
              display: "flex",
              justifyItems: "space-between",
              alignItems: "center",
            }}
          >
            <h1 style={{ margin: 0, flex: 1 }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                }}
              >
                {siteTitle}
              </Link>
            </h1>
            <div>
              <Burger menuLinks = {menuLinks}  />       
              <nav>
                <ul sx={{ display: ['none','none','flex',"flex","flex"], flex: 1, margin: 0, padding:0, }}>
                  {menuLinks.map(link => (
                    <li
                      key={link.name}
                      style={{
                        listStyleType: `none`,
                        lineHeight:`36px`,
                        padding: `1rem`,
                      }}
                    >
                      <Link to={link.link}>
                        {link.name}
                      </Link>
                    </li>
                  ))}
                  <li style={{
                        listStyleType: `none`,
                        padding: `1rem`,
                      }}><ThemeSwitcher /> </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>  
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}
Header.defaultProps = {
  siteTitle: ``,
}
export default Header