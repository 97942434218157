/** @jsx jsx */
import { jsx } from "theme-ui"
import React from 'react'
// import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Header from '../Navigation/Header'
import SideBar from '../Navigation/SideBar'
import { StaticQuery, graphql } from "gatsby"
import Logo from '../../images/Onion.png'


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            author
            description
            social {
              twitter
              netease
              linkedin
              github
              email
            }
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <Helmet
          title={'Onioner | To be positive everyday!'}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
        </Helmet>
        <SideBar siteMetadata = {data.site.siteMetadata} Logo={Logo} />
        <div sx={{marginLeft:[0, 0, 0, 240]}}>
          <Header menuLinks={data.site.siteMetadata.menuLinks} siteTitle={data.site.siteMetadata.title} />      
          <div
            sx={{
              margin: '0 auto',
              maxWidth: 960,
              padding: '0px .5rem 1.45rem',
              paddingTop: ['2.45rem', '2.45rem', 0, 0, 0],
            }}
          >     
            {children}
          </div>
        </div>  
      </React.Fragment>
    )}
  />
)

export default Layout

// const themeStyles = {
//   layout: {
//     position: `relative`, 
//     maxWidth: 1250,
//     margin: `0 auto`,
//     ">div":{
//       // px: 24,
//       ml: [0, null, null, 240],
//     },
//   }
// }  