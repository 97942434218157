/** @jsx jsx */
import { jsx } from "theme-ui"
// import React from 'react'
import { Link, } from "gatsby"
import { IconContext } from "react-icons";
import {RiNeteaseCloudMusicFill, RiTwitterFill, RiGithubFill, RiLinkedinBoxFill, RiMailFill } from "react-icons/ri";

const SideBar = ({ Logo, siteMetadata }) => {
  
  return(
    <aside sx={themeStyles.sidebar}>
      <header>
        <div className="about">
          <div className="cover-author-image">
            <Link to="/">
              <img src={Logo} alt={siteMetadata.author} style={{width:96,}} />
            </Link>
          </div>
          <h3 className="author-name">{siteMetadata.author}</h3>
          <p>{siteMetadata.description}</p>
        </div>
      </header>
      <footer>
        <section className="contact">
          <h3 className="contact-title">Contact me</h3>
          <IconContext.Provider value={{ color: "text", size:"24", className: "icon-f" }}>
          <ul>
            {siteMetadata.social.twitter && (
              <li>
                <a
                  href={`https://twitter.com/${siteMetadata.social.twitter}`}
                  target="_blank" rel="noreferrer"
                >
                  <RiTwitterFill sx={{ '&:hover':{color:"#1da1f2" } }}/>
                </a>
              </li>
            )}
            {siteMetadata.social.netease && (
              <li>
                <a
                  href={`https://music.163.com/#/user/home?id=${siteMetadata.social.netease}`}
                  target="_blank" rel="noreferrer"
                >
                  <RiNeteaseCloudMusicFill sx={{ '&:hover':{color:"#f00000" } }} />
                </a>
              </li>
            )}
            {siteMetadata.social.github && (
              <li>
                <a
                  href={`https://github.com/${siteMetadata.social.github}`}
                  target="_blank" rel="noreferrer"
                >
                  <RiGithubFill sx={{ '&:hover':{color:"#2a39b0" } }}/>
                </a>
              </li>
            )}
            {siteMetadata.social.linkedin && (
              <li>
                <a
                  href={`https://linkedin.com/in/${siteMetadata.social.linkedin}`}
                  target="_blank" rel="noreferrer"
                >
                  <RiLinkedinBoxFill sx={{ '&:hover':{color:"#0096ff" } }}/>
                </a>
              </li>
            )}
            {siteMetadata.social.email && (
              <li>
                <a href={`mailto:${siteMetadata.social.email}`} target="_blank" rel="noreferrer">
                  <RiMailFill sx={{ '&:hover':{color:"#f3dac4" } }}/>
                </a>
              </li>
            )}
          </ul>
          </IconContext.Provider>
        </section>
        <div className="copyright">
          <p>
            {new Date().getFullYear()} &copy; {siteMetadata.author} <img
        alt="Gatsby G Logo"
        src="data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.8 1a6.8 6.8 90 110 13.6a6.8 6.8 90 010-13.6zm0 1.36c-2.516 0-4.692 1.7-5.304 4.08l6.664 6.664c2.312-.612 4.08-2.72 4.08-5.304h-3.604v1.02h2.312a4.352 4.352 90 01-2.652 2.992l-5.644-5.644c.612-1.632 2.176-2.788 4.012-2.788c1.428 0 2.72.68 3.536 1.768l.816-.748a5.44 5.44 90 00-4.352-2.176zm-5.44 5.44a5.44 5.44 90 005.44 5.44c0 0 .068 0-5.44-5.44z' fill='%23639'/%3E%3C/svg%3E"
      />
          </p>
        </div>
      </footer>
    </aside>
  )
}

export default SideBar

const themeStyles = {
  sidebar:{
    display: ["none", null, null, "flex"],
    flexDirection: "column",
    justifyContent: "space-between",
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    width: 240,
    height: "100%",
    backgroundColor: "var(--theme-ui-colors-backgroundLighten10)",
    textAlign: "center",
    alignItems: "center",
    p: [5, 8],
    pb: [13, 8],
    fontSize: "15px",
    h5: { my: 4, fontSize: 3 },
    "h5:not(:first-of-type)": { mb: 10, mt: 0 },
    ".guest": {
      fontSize: 1,
      textTransform: "uppercase",
      opacity: 0.8,
      fontWeight: "body",
    },
    ".cover-author-image": {
      border: "solid 6px",
      padding: "12px",
      boxShadow: "inset 0 0 1px 0 rgba(0, 0, 0, 0.2)",
    },
    ul: {m: 0, p: 0},
    li: {
      mb: 2,
      listStyle:"none",
      display: "inline-block",
      a: { color: "text" },
      svg: {
        mt: 1,
        mr: 1,
        width: "100%",
        color: "text",
        // opacity: 0.8,
      },
    },
    '.icon-f': {
      '&:hover': {
        transform: "scale(1.2)",
        transition: "all .35s cubic-bezier(.13,.43,.54,1.82)",
      },
    }
  }
}